import { CmsGuideLayout } from "../../../components/CmsGuideLayout"
import { PageDataBodyWeRideHero } from "../../../slices/PageDataBodyWeRideHero"

const Component = () => {
	return (
		<CmsGuideLayout>
			<PageDataBodyWeRideHero
				copyList={[
					{
						richText: [
							{
								type: "paragraph",
								text: "to connect neighborhoods, communities, and people to a common cause.",
								spans: [
									{
										start: 0,
										end: 10,
										type: "strong",
									},
								],
							},
						],
					},
					{
						richText: [
							{
								type: "paragraph",
								text: "to inspire patients, survivors, and everyone whose life has been touched by cancer. We ride for researchers, clinicians, nurses, and everyone who works every day to change lives.",
								spans: [
									{
										start: 0,
										end: 10,
										type: "strong",
									},
								],
							},
						],
					},
					{
						richText: [
							{
								type: "paragraph",
								text: "to celebrate the generosity, compassion and energy of our communities.",
								spans: [
									{
										start: 0,
										end: 12,
										type: "strong",
									},
								],
							},
						],
					},
					{
						richText: [
							{
								type: "paragraph",
								text: "to have fun as we ride through our beautiful city and its unique neighborhoods at our own pace, alone or with a group of friends, amid a community united in a powerful cause.",
								spans: [
									{
										start: 0,
										end: 11,
										type: "strong",
									},
								],
							},
						],
					},
					{
						richText: [
							{
								type: "paragraph",
								text: "to get to the next level. At UPMC Hillman Cancer Center, we are constantly pushing the boundaries of research as we strive to better understand, diagnose, treat, and prevent cancer. ",
								spans: [
									{
										start: 0,
										end: 25,
										type: "strong",
									},
								],
							},
						],
					},
					{
						richText: [
							{
								type: "paragraph",
								text: "to get miles closer to achieving the extraordinary: life without cancer. We are getting closer every day.",
								spans: [
									{
										start: 0,
										end: 19,
										type: "strong",
									},
								],
							},
						],
					},
				]}
			/>
		</CmsGuideLayout>
	)
}

export default Component
